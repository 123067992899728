import { Injectable } from '@angular/core';
import { Settings } from 'src/app/app.settings.model';

@Injectable()
export class AppSettings {

public settings = new Settings(
        'Fotovoltaica',
        'Fotovoltaica',
        {
            navbarIsFixed: true,
            footerIsFixed: true,
            sidebarIsFixed: true,
            skin:'combined',  //light , dark, blue, green, combined, purple, orange, brown, grey, pink
        },
        {
            googleMapsAPIKey: 'AIzaSyD57tSVhrq5N_MRrWzJVh_n4LCJcdCubkY',
            feature_googleMaps: true,
            googleAnalytics: '',
            feature_googleAnalytics: false,
            googleTagManager: '',
            feature_googleTagManager: true,
            feature_preview_dialog: true,
            protocol: 'https',
            host: 'app.energysequence.com',
            port: 443,
            calculatorEndpoint: 'v2/pv/',
            simplifiedEndpoint: 'v2/ssc/',
            loginEndpoint: 'v2/pvauth/',
            tosURL: 'https://www.aficlima.com/politica-de-privacidad/',
        },
       {
            id: '13',
            name: 'Aficlima',
            email: 'info@aficlima.com',
            web: 'https://www.aficlima.com/',
            logo: '',
            instagram: 'https://www.instagram.com/Aficlimasolar/',
            twitter: 'https://twitter.com/aficlimasolar/',
            youtube: '',
            linkedin:'https://www.linkedin.com/company/afliclimasolar/',
            facebook: 'https://www.facebook.com/Aficlimasolar/',
            privacyPolicies: 'https://www.aficlima.com/aviso-legal/',
            backgroundPhoto: '/assets/img/app/background.jpg',
            buttonStyle: 'btn-outline-primary',
            fontFamily: '',
            navbarLogoDimension: '',
            showHeader: 1,
            showFooter: 1,
            welcome_text: 'Calcula tu instalación solar fotovoltaica',
            promotion_text: '', //¡Enhorabuena, ya estás a un paso de ahorrar en tu factura de la luz y contribuir a mejorar el medio ambiente!
            telephone_required: 1,
            name_required: 1,
            cups_required: 0,
            allow_login: 0,
            inital_map_latitude: 37.1757663,
            inital_map_longitude: -3.5898807,
            investment_renting: 1,
            simplified_mode: false,
            home_costs: [50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300],
            company_costs: [250, 500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 3250, 3500, 3750, 4000],
            feature_installation_costs: true,
            feature_cost_saving_percent: false
        }
    )
}

